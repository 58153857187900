import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Carousel,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import LuteinComplexMobile from "./../images/Prostatizal-plus-mobile.jpg"
import LuteinComplexTablet from "./../images/Prostatizal-plus-tablet.jpg"
import LuteinComplexDesktop from "./../images/Prostatizal-plus-desktop.jpg"
import LuteinComplexXL from "./../images/Prostatizal-plus-desktop-xl.jpg"
import Feature1 from "./../images/prost-plus-prostatitis.jpg"
import Feature2 from "./../images/prost-plus-herbs.jpg"
import Feature3 from "./../images/prost-plus-hazelnut.jpg"
import Feature4 from "./../images/prost-psor-plus-herbal-guarantee.jpg"
import Testimonial1 from "./../images/dr-svetlin-dimitrov.jpg"
import Testimonial2 from "./../images/daniela-petkova.jpg"

const ProstatizalPlusPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "ProstatizalPlus" }}>
    <Seo title="Prostatizal Plus - Решението на проблемите с простатата" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>PROSTATIZAL PLUS</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Prostatizal Plus"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Prostatizal Plus
          </h5>
          <h1 className="display-4 font-light">
            Решението на проблемите с простатата
          </h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">
            Prostatizal Plus
          </h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Решението на проблемите с простатата
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Простатизал Плюс е натурално средство за укрепване и възстановяване
            на дейността на простатната жлеза.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/bg/vh6-prostatizal-plus"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Купете онлайн от <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Продава се в аптеките в цялата страна</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>без разтворители</li>
                <li>без консерванти</li>
                <li>без глутен</li>
                <li>без захар</li>
                <li>без ГМО</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Укрепване на простатата</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Укрепване на простатата"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Простатизал Плюс е натурален продукт, който спомага за укрепване и
            възстановяване дейността на простатната жлеза. Подбраните билкови
            екстракти съдържат биологично активни вещества – фитостероли,
            сапонини, танини, органични киселини, които подкрепят здравето на
            простатата и подпомагат функциите на отделителната и половата
            система при мъжете.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">При болезнено уриниране и възпаления</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="При болезнено уриниране и възпаления"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Екстрактът от леска притежава пикочогонно, противовъзпалително и
            съдосвиващо действие. Дребноцветната върбовка има диуретичен ефект и
            повлиява благоприятно затрудненото, често и болезнено уриниране. От
            своя страна екстрактът от гръмотрън, допълващ действието на
            дребноцветната върбовка е със силно отводняващо действие и съдейства
            за премахване задържането на урина в пикочния мехур и за
            отстраняване на пикочните инфекции. Биологично активните вещества,
            извлечени от невена, имат противомикробно и противовъзпалително
            действие.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Благоприятни ефекти върху отделителната система
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Благоприятни ефекти върху отделителната система"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Напълно натурален, той съдържа стандартизиран сух екстракт от
            билките леска, дребноцветна върбовка, гръмотрън и невен. Биологично
            активните вещества, извлечени от тези билки, притежават много добро
            противовъзпалително, диуретично, спазмолитично и болкоуспокоително
            действие. Простатизал Плюс повлиява благоприятно затрудненото, често
            и болезнено уриниране. Постепенно премахва задържането на урина в
            пикочния мехур. Съдейства и за отстраняване на пикочните инфекции,
            които се явяват често усложнение на простатната хиперплазия.
            Простатизал Плюс спомага и за стимулиране на сексуалната енергия при
            мъжете.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Гаранция за качество и сигурност</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Гаранция за качество и сигурност"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Стандартизирани растителни екстракти се получават от растения или
            техни части чрез извличане на биологично активни компоненти. Тяхното
            приемущество е, че вида на активните вещества може да се контролира
            и поддържа в определено количество и качество. Приемът на екстракт с
            доказан ефект гарантира оптимално въздействие върху функциите на
            организма. Растителните екстракти, включени в Простатизал Плюс,
            допълват взаимно благоприятните си ефекти.
          </p>
        </Col>
      </Row>

      <Row className="product-container mb-2">
        <Col>
          <h5 className="section-heading">
            Мнения на специалисти
            <br />и потребители
          </h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Carousel
            indicators={false}
            fade={false}
            interval={4500}
            className="shadow-1 mx-auto testimonial-container"
          >
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial1}
                  alt="д-р Светлин Димитров"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">
                      Решението на проблемите с простатата
                    </h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    В моята дългогодишна практика на уролог, аз изписвам много
                    природни продукти, като през последните 4 години препоръчвам
                    най-често ПРОСТАТИЗАЛ ПЛЮС на Вита Херб, като практически
                    съм убеден в клиничното му дейст- вие. ПРОСТАТИЗАЛ ПЛЮС е
                    натурален продукт, който подпомага укрепването и
                    възстановяването дейността на простатната жлеза. Той действа
                    диуретично, противовъзпалително и антимикробно. Съдейства за
                    отстраняване на пикочните инфекции, които се явяват като
                    често усложнение. ПРОСТАТИЗАЛ ПЛЮС спомага и за стимулиране
                    на сексуалната енергия. Не забравяйте, че своевременната
                    профилактика с ПРОСТАТИЗАЛ ПЛЮС и консултация с уролог,
                    гарантира здравето и сексуалния живот при мъжа над 50
                    години.
                    <br />
                    <br />
                    Гл. Ас. д-р Светлин Димитров, дм, клиника по Урология УМБАЛ
                    „Александровска“, София
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial2}
                  alt="Даниела Петкова"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">Погрижете се за простатата</h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    На пациентите ми над петдесетгодишна възраст препоръчвам
                    ПРОСТАТИЗАЛ ПЛЮС. Това е натурален продукт, който е
                    предназначен за укрепване на простатната жлеза и
                    възстановява дейността й.
                    <br />
                    Растителните екстракти включени в ПРОСТАТИЗАЛ ПЛЮС съдържат
                    активни вещества, които оказват положителен ефект върху
                    отделителната и половата система при мъжете и повишава
                    сексуалната активност.
                    <br />
                    Пациентите са доволни от резулта- тите, които постигат с
                    ПРОСТАТИЗАЛ ПЛЮС, затова аз го препоръчвам уверено.
                    <br />
                    <br />
                    Даниела Петкова, Маг.-фармацевт,
                    <br />
                    гр. Пловдив
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Повече за Простатизал Плюс</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Състав
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Дребноцветна върбовка</strong> – стрък (Extr. Hb.
                    Epilodium parviflorum) 70 mg
                  </p>
                  <p>
                    <strong>Леска</strong> – кори (Extr. Cort. Corylus
                    avellanae) 60 mg
                  </p>
                  <p>
                    <strong>Гръмотрън</strong> – корени (Extr. Rad. Ononis
                    spinosa) 50 mg
                  </p>
                  <p>
                    <strong>Невен</strong> – цвят (Extr. Fl. Calendula
                    officinalis) 20 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Прием
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Простатизал Плюс се приема по 1 таблетка 2 пъти дневно след
                    хранене. Препоръчително е продължителността на приема да не
                    е по-малко от 3 месеца.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Противопоказания
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Не са установени. Не се препоръчва при лица с алергия към
                    пшенични продукти. Простатизал Плюс е хранителна добавка и
                    не предизвиква дразнене и свръхчувствителност и може да се
                    приема продължително време. Простатизал Плюс може да се
                    приема самостоятелно или в комбинация с предписани от
                    лекуващия лекар медикаменти.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Действие
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Екстрактът от леска притежава пикочогонно,
                    противовъзпалително и съдосвиващо действие. Дребноцветната
                    върбовка има диуретичен ефект и повлиява благоприятно
                    затрудненото, често и болезнено уриниране. От своя страна
                    екстрактът от гръмотрън, допълващ действието на
                    дребноцветната върбовка е със силно отводняващо действие и
                    съдейства за премахване задържането на урина в пикочния
                    мехур и за отстраняване на пикочните инфекции. Биологично
                    активните вещества, извлечени от невена, имат
                    противомикробно и противовъзпалително действие.
                  </p>
                  <p>
                    <a href="https://vitaherb.bg/leaflets/Prostatizal_Plus-LISTOVKA.pdf">
                      Листовка на продукта
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ProstatizalPlusPage
